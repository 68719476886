import React from "react";
import "./BanBody.css";
import {icon_surprise} from "../../const/ConstImages";
export const BanBody = () => {
    return(
        <div className="sub_content">
            <div style={{
                textAlign: "center",
            }}>
                <img src={icon_surprise} alt="icon-surprise" />
            </div>
            <div className="center_content">
                Account has been permanently banned!
                <br />
                <br />
                <strong>Reason : </strong> PayPal fraud
                <br />
                <br />
                <br />
                <br />
                <div className="black_button">Contact us</div>
            </div>
        </div>
    )
}