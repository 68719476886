import React from "react";
import "./MainNoticeItem.css";
import {main_thumb_001 } from "../../const/ConstImages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPlus} from "@fortawesome/free-solid-svg-icons";

export const MainNoticeItem = () => {
    return (
        <>
            <h1 className="d-flex justify-content-between h1 notice_title">
                <a href={"#11"}>Notices</a>
                <FontAwesomeIcon icon={faPlus} style={{
                    paddingRight: 10,
                }} />
            </h1>
            <div
                id="contentdiv"
                style={{
                    width: "100%",
                    height: "100%",
                    background: "#ffffff",
                    paddingBottom: 19
                }}
            >
                <a href="#" style={{ textDecoration: "none" }}>
                    <div id="mainnew">
                        <div
                            style={{
                                height: 269,
                                backgroundImage: `url(${main_thumb_001})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover"
                            }}
                        />
                        <div
                            className="text-center"
                            style={{
                                fontSize: 21,
                                fontFamily: '"Source Sans Pro", sans-serif',
                                paddingTop: 40,
                                paddingRight: 20,
                                paddingBottom: 40,
                                paddingLeft: 20
                            }}
                        >
          <span
              id="noticetext"
              className="p2"
              style={{ paddingRight: 20, paddingLeft: 20 }}
          >
            [09-23]&nbsp;
              <span className="p3">
              Special Montly Top-up Ranking now available
            </span>
          </span>
                            <span
                                id="noticetext"
                                className="p4"
                                style={{ paddingRight: 50, paddingLeft: 50, marginTop: 5 }}
                            >
            Take part at the monthly top-up rank and compete for amazing rewards
          </span>
                        </div>
                    </div>
                </a>
                <div
                    style={{
                        padding: 30,
                        borderTop: "1px solid rgb(230,230,230)",
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingRight: 40,
                        paddingLeft: 40
                    }}
                >
                    <a id="noticetext2" className="p1" href="#">
                        <span className="p2">[09-10]</span>&nbsp;Weeknd Promotional Top-up
                        Discount&nbsp;
                    </a>
                </div>
                <div
                    style={{
                        padding: 30,
                        borderTop: "1px solid rgb(230,230,230)",
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingRight: 40,
                        paddingLeft: 40
                    }}
                >
                    <a id="noticetext2" className="p1" href="#">
                        <span className="p2">[09-10]</span>&nbsp;Community Event!&nbsp;
                    </a>
                </div>
            </div>
        </>

    );
}