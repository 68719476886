import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./NoticeDetail.css";

export const NoticeDetail = () => {
    return(
        <Container className="justify-content-center" >
            <Row>
                <Col className="border-bottom detail_title">Update Details - July 6. 2023</Col>
            </Row>
            <Row className="border-bottom">
                <Col md={8} className="detail_content">
                    <br/>1. New Character:
                    <br/>1. New Character:
                    <br/>1. New Character:
                    <br/>1. New Character:
                    <br/>1. New Character:
                    <br/>1. New Character:
                    <br/>1. New Character:
                    <br/>1. New Character:
                    <br/>1. New Character:
                    <br/>1. New Character:
                </Col>
                <Col md={3} className="border-start">
                    <div className="side_list">
                        <div>[Notice]</div>
                        <div className="content">
                            asdlfjkalksd asdklfj as asdlkfjaslk asdfkasjflasjkdfl
                            aasdklfjaslkfjalksjfsajdfkasljflkasjfklasdjflkas askdfjalsf
                        </div>
                        <div className="date">July 6. 2023</div>
                    </div>
                    <div className="side_list">
                        <div>[Notice]</div>
                        <div className="content">
                            asdlfjkalksd asdklfj as asdlkfjaslk asdfkasjflasjkdfl
                            aasdklfjaslkfjalksjfsajdfkasljflkasjfklasdjflkas askdfjalsf
                        </div>
                        <div className="date">July 6. 2023</div>
                    </div>
                    <div className="side_list">
                        <div>[Notice]</div>
                        <div className="content">
                            asdlfjkalksd asdklfj as asdlkfjaslk asdfkasjflasjkdfl
                            aasdklfjaslkfjalksjfsajdfkasljflkasjfklasdjflkas askdfjalsf
                        </div>
                        <div className="date">July 6. 2023</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <input type="button" value="View List" className="list_back_btn" />
                </Col>
            </Row>
        </Container>
    );
}