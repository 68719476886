import React from "react";
import {BanHeader} from "../components/BanHeader/BanHeader";
import {BanBody} from "../components/BanBody/BanBody";

export const BanScreen = () => {
    return(
        <>
            <BanHeader />
            <BanBody />
        </>
    )
}