import React from "react";
import "./MainYoutubeItem.css";
import {youtube} from "../../const/ConstImages";

export const MainYoutubeItem = () => {
    return (
        <div
            className="card"
            style={{
                borderRadius: 0,
                borderStyle: "none",
                borderRightStyle: "solid",
                borderRightColor: "rgba(0,0,0,0.06)",
                padding: 30,
                height: "100%",
                marginBottom: 1
            }}
        >
            <iframe
                allowFullScreen
                frameBorder={0}
                src="https://www.youtube.com/embed/qep9J1Y-iHo?controls=0"
                style={{ width: "100%", height: 210 }}
            />
            <div style={{ marginTop: 18 }}>
                <span style={{ color: "rgb(227,43,43)", fontSize: 17 }}>
                  <img
                      src={youtube}
                      style={{ width: 25, marginTop: "-2px" }}
                  />
                    &nbsp;
                </span>
                <a id="youtuberlink" className="p1" href="#">
                    &nbsp;Story of Vulcan - Update Trailer
                </a>
            </div>
        </div>
    );
}