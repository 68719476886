
export const _BASE_IMG_URL: string = "https://img.cbmgames.com/www_test/";

export const test_11: string = "";
export const plus: string = _BASE_IMG_URL + 'plus.png';
export const Sub_map_thumb: string = _BASE_IMG_URL + 'Sub-map-thumb.png';
export const Sub_overwiew_movie: string = _BASE_IMG_URL + 'Sub-overwiew-movie.png';
export const Sub_overwiew_page_001: string = _BASE_IMG_URL + 'Sub-overwiew-page-001.png';
export const Sub_overwiew_page_002: string = _BASE_IMG_URL + 'Sub-overwiew-page-002.png';
export const Sub_top_001: string = _BASE_IMG_URL + 'Sub-top-001.png';
export const Sub_top_002: string = _BASE_IMG_URL + 'Sub-top-002.png';
export const amd: string = _BASE_IMG_URL + 'amd.png';
export const c_Cleric: string = _BASE_IMG_URL + 'c-Cleric.png';
export const c_DualGunners: string = _BASE_IMG_URL + 'c-DualGunners.png';
export const c_Paladins: string = _BASE_IMG_URL + 'c-Paladins.png';
export const c_Rifleman: string = _BASE_IMG_URL + 'c-Rifleman.png';
export const c_Warlocks: string = _BASE_IMG_URL + 'c-Warlocks.png';
export const c_Warrior: string = _BASE_IMG_URL + 'c-Warrior.png';
export const c_princess: string = _BASE_IMG_URL + 'c-princess.png';
export const c_tab_Cleric_off: string = _BASE_IMG_URL + 'c-tab-Cleric-off.png';
export const c_tab_Cleric_on: string = _BASE_IMG_URL + 'c-tab-Cleric-on.png';
export const c_tab_DualGunners_off: string = _BASE_IMG_URL + 'c-tab-DualGunners-off.png';
export const c_tab_DualGunners_on: string = _BASE_IMG_URL + 'c-tab-DualGunners-on.png';
export const c_tab_Paladins_off: string = _BASE_IMG_URL + 'c-tab-Paladins-off.png';
export const c_tab_Paladins_on: string = _BASE_IMG_URL + 'c-tab-Paladins-on.png';
export const c_tab_Rifleman_off: string = _BASE_IMG_URL + 'c-tab-Rifleman-off.png';
export const c_tab_Rifleman_on: string = _BASE_IMG_URL + 'c-tab-Rifleman-on.png';
export const c_tab_Warlocks_off: string = _BASE_IMG_URL + 'c-tab-Warlocks-off.png';
export const c_tab_Warlocks_on: string = _BASE_IMG_URL + 'c-tab-Warlocks-on.png';
export const c_tab_Warrior_off: string = _BASE_IMG_URL + 'c-tab-Warrior-off.png';
export const c_tab_Warrior_on: string = _BASE_IMG_URL + 'c-tab-Warrior-on.png';
export const c_tab_bg: string = _BASE_IMG_URL + 'c-tab-bg.jpg';
export const c_tab_princess_hold: string = _BASE_IMG_URL + 'c-tab-princess-hold.png';
export const contactus: string = _BASE_IMG_URL + 'contactus.png';
export const directx: string = _BASE_IMG_URL + 'directx.png';
export const discode__: string = _BASE_IMG_URL + 'discode--.png';
export const discode: string = _BASE_IMG_URL + 'discode.png';
export const down_btn: string = _BASE_IMG_URL + 'down-btn.png';
export const findus: string = _BASE_IMG_URL + 'findus.png';
export const icon_check: string = _BASE_IMG_URL + 'icon-check.png';
export const icon_surprise: string = _BASE_IMG_URL + 'icon-surprise.png';
export const join_img: string = _BASE_IMG_URL + 'join-img.png';
export const joinour: string = _BASE_IMG_URL + 'joinour.png';
export const main_arrow: string = _BASE_IMG_URL + 'main-arrow.png';
export const main_thumb_001: string = _BASE_IMG_URL + 'main-thumb-001.png';
export const main_thumb_002: string = _BASE_IMG_URL + 'main-thumb-002.png';
export const main_thumb_003: string = _BASE_IMG_URL + 'main-thumb-003.png';
export const main_thumb_004: string = _BASE_IMG_URL + 'main-thumb-004.png';
export const main_thumb_005: string = _BASE_IMG_URL + 'main-thumb-005.png';
export const main_thumb_006: string = _BASE_IMG_URL + 'main-thumb-006.png';
export const main_top_arrow: string = _BASE_IMG_URL + 'main-top-arrow.png';
export const main_top_img: string = _BASE_IMG_URL + 'main-top-img.png';
export const main_youtube_img: string = _BASE_IMG_URL + 'main-youtube-img.png';
export const mcoin: string = _BASE_IMG_URL + 'mcoin.png';
export const navi_logo: string = _BASE_IMG_URL + 'navi-logo.png';
export const nvidia: string = _BASE_IMG_URL + 'nvidia.png';
export const overview: string = _BASE_IMG_URL + 'overview.png';
export const play_now_ikon: string = _BASE_IMG_URL + 'play-now-ikon.png';
export const sub_ch_movie_search: string = _BASE_IMG_URL + 'sub-ch-movie-search.png';
export const sub_ch_movie_thumb: string = _BASE_IMG_URL + 'sub-ch-movie-thumb.png';
export const sub_ch001: string = _BASE_IMG_URL + 'sub-ch001.png';
export const sub_download_install: string = _BASE_IMG_URL + 'sub-download-install.png';
export const sub_list__: string = _BASE_IMG_URL + 'sub-list--.png';
export const sub_list_btn: string = _BASE_IMG_URL + 'sub-list-btn.png';
export const sub_list_img: string = _BASE_IMG_URL + 'sub-list-img.png';
export const sub_list_new: string = _BASE_IMG_URL + 'sub-list-new.png';
export const sub_list_search: string = _BASE_IMG_URL + 'sub-list-search.png';
export const sub_list_srrow: string = _BASE_IMG_URL + 'sub-list-srrow.png';
export const sub_log_btn: string = _BASE_IMG_URL + 'sub_log-btn.png';
export const sub_log_logo: string = _BASE_IMG_URL + 'sub_log-logo.png';
export const temp_footer_logos: string = _BASE_IMG_URL + 'temp_footer_logos.png';
export const temp_guide1_video: string = _BASE_IMG_URL + 'temp_guide1_video.png';
export const temp_job_screenshot: string = _BASE_IMG_URL + 'temp_job_screenshot.png';
export const temp_sub_overview_page_003: string = _BASE_IMG_URL + 'temp_sub-overview-page-003.png';
export const the_game_of: string = _BASE_IMG_URL + 'the-game-of.png';
export const youtube: string = _BASE_IMG_URL + 'youtube.png';