import React from "react";
import {Col, Row} from "react-bootstrap";
import "./NoticeCate.css";

export const NoticeCate = () => {
    return (
        <Row className="justify-content-center border-bottom" style={{
            paddingTop: 40,
            backgroundColor: "white",
        }}>
            <Col className="notice_menu_item" md={2}>Show All</Col>
            <Col className="notice_menu_item" md={2}>Update</Col>
            <Col className="notice_menu_item" md={2}>Events</Col>
            <Col className="notice_menu_item" md={2}>Notices</Col>
        </Row>
    )
}