import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import "./MainMidContainer.css";
import {contactus, discode, findus, mcoin} from "../../const/ConstImages";

export const MainMidContainer = () => {
    return (
        <div style={{
            // height: 70,
            backgroundColor: "white",
        }}>
            <Container>
                <Row className="justify-content-md-center" >
                    <Col md={3} className="mid_btn align-middle text-center">
                        <img src={discode} alt="discode" />
                    </Col>
                    <Col md={3} className="mid_btn align-middle text-center">
                        <img src={findus} alt="discode" />
                    </Col>
                    <Col md={3} className="mid_btn align-middle text-center">
                        <img src={contactus} alt="discode" />
                    </Col>
                    <Col md={3} className="mid_btn align-middle text-center">
                        <img src={mcoin} alt="discode" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}