import React from "react";
import {Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import {navi_logo, play_now_ikon} from "../../const/ConstImages";
import "./CmmnNavbar.css";

const CmmnNavbar = () => {
    return (
        <>
            <Navbar expand="lg" className="navbar-dark bg-dark" fixed={"top"}>
                <Container fluid style={{
                    maxWidth: 1920,
                    minWidth: 420,
                }} className="navi_container">
                    <Navbar.Brand href="#home">
                        <img src={navi_logo} alt="navi_logo" width="144" height="40" className="d-inline-block align-top" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home" className="item">Announcements</Nav.Link>
                            <Nav.Link href="#link" className="item">Game Features</Nav.Link>
                            <Nav.Link href="#link" className="item">Ranking</Nav.Link>
                            <Nav.Link href="#link" className="item">Support</Nav.Link>
                            <Nav.Link href="#link" className="item">Download</Nav.Link>
                            <Nav.Link href="#link" className="item">Community</Nav.Link>
                            <Nav.Link href="#link" className="item">Shop</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>


                    <div
                        className="d-flex align-items-center"
                        id="userdiv"
                        style={{ height: "100%", marginTop: 0 }}
                    >
                        <div className="dropleft" style={{ width: "40px", float: "left" }}>
                            <NavDropdown id="basic-nav-dropdown"
                                         align="end"
                                         title={
                                             <img src={play_now_ikon} style={{ width: 30, marginTop: 1 }} />
                                         }
                            >
                                <NavDropdown.Item>
                                    <div
                                        id="dropdown-user"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            padding: 15,
                                            background: "rgba(3,3,3,0.9)",
                                            borderRadius: 4,
                                            display: "flex"
                                        }}
                                    >
                                        <a
                                            className="btn btn-primary d-flex justify-content-center align-items-center"
                                            role="button"
                                            id="btn_login"
                                            style={{ marginRight: 15 }}
                                        >
                                            Login
                                        </a>
                                        <a
                                            className="btn btn-primary d-flex justify-content-center align-items-center"
                                            role="button"
                                            id="btn_create"
                                        >
                                            Create Account
                                        </a>
                                    </div>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>
                        <div><a className="playnow" href="#playnow">PLAY NOW</a></div>
                    </div>
                    {/*<div><a className="playnow" href="#playnow">PLAY NOW</a></div>*/}
                </Container>
            </Navbar>



        </>
    );
}

export default CmmnNavbar;