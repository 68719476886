import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {contactus, discode, findus, mcoin} from "../../const/ConstImages";
import {MainNoticeItem} from "../MainNoticeItem/MainNoticeItem";

export const MainNoticeContainer = () => {
    return (
        <div style={{width: "100%", height: "100%", backgroundColor: "#E6E6E6", paddingTop: 20}}>
            <Container
                style={{
                    paddingTop: 20,
                }}
                fluid
            >
                <Row className="justify-content-center justify-content-between" >
                    <Col md={1}></Col>
                    <Col md={3} className="align-middle text-center">
                        <MainNoticeItem />
                    </Col>
                    <Col md={3} className="align-middle text-center">
                        <MainNoticeItem />
                    </Col>
                    <Col md={3} className="align-middle text-center">
                        <MainNoticeItem />
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </div>
    );
}