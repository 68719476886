import React from "react";
import {LoginHeader} from "../components/LoginHeader/LoginHeader";
import {LoginBody} from "../components/LoginBody/LoginBody";


export const LoginScreen = () => {
    return (
        <>
            <LoginHeader />
            <LoginBody />
        </>
    )
}