import React from "react";
import "./DownloadBody.css";
import {amd, directx, nvidia} from "../../const/ConstImages";

export const DownloadBody = () => {
    return(
        <div className="sub_content_download">
            <div className="download_title">System requirements</div>
            <table>
                <tbody>
                <tr>
                    <th>Category</th>
                    <th>Minimum</th>
                    <td>Recommended</td>
                </tr>
                <tr>
                    <th>CPU</th>
                    <td>Pentium4 1.8GHz</td>
                    <td>Pentium dual core</td>
                </tr>
                <tr>
                    <th>Hard Drive</th>
                    <td colSpan={2}>Over 2GB</td>
                </tr>
                <tr>
                    <th>Memory</th>
                    <td>1 GB Ram</td>
                    <td>2 GB Ram</td>
                </tr>
                <tr>
                    <th>GPU</th>
                    <td>Over 256 MB 3D</td>
                    <td>Over 512 MB 3d</td>
                </tr>
                <tr>
                    <th>OS</th>
                    <td colSpan={2}>Window 2000, XP, Vista, Window 7 &amp; 10 &amp; 11</td>
                </tr>
                </tbody>
            </table>
            <div className="desc">
                * For the best Aika Online experience. Please install the latest Direct X
                service pack.
            </div>
            <div className="driver_title">Latest Drivers &amp; Direct X Install</div>
            <div className="driver_buttons">
                <div className="item">
                    <div className="driver_img">
                        <img src={nvidia} />
                    </div>
                    <div className="install_text">Install</div>
                </div>
                <div className="space" />
                <div className="item">
                    <div className="driver_img">
                        <img src={amd} />
                    </div>
                    <div className="install_text">Install</div>
                </div>
                <div className="space" />
                <div className="item">
                    <div className="driver_img">
                        <img src={directx} />
                    </div>
                    <div className="install_text">Install</div>
                </div>
            </div>
        </div>

    )
}