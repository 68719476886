import React from "react";
import {NoticeHeader} from "../components/NoticeHeader/NoticeHeader";
import {NoticeCate} from "../components/NoticeCate/NoticeCate";
import {NoticeItem} from "../components/NoticeItem/NoticeItem";
import {CmmnPaging} from "../components/CmmnPaging/CmmnPaging";

export const NoticeListScreen = () => {
    return(
        <>
            <NoticeHeader />
            <NoticeCate />
            <NoticeItem />
            <NoticeItem />
            <NoticeItem />
            <NoticeItem />

            <CmmnPaging />
        </>
    )
}