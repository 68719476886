import React from "react";
import "./MainJoinCommunity.css";
import {discode__, join_img, joinour} from "../../const/ConstImages";
import {Col, Container, Row} from "react-bootstrap";

export const MainJoinCommunity = () => {
    return(
        <div style={{
            width: "100%",
            height: "100%",
            background: "#E6E6E6",
        }}>
            <div id="playnow" className="maindiv" style={{
                background: `url(${join_img}) top / cover no-repeat`,
            }}>
                <h1 id="heading-playnow" className="h">
                    Join our community
                </h1>
                <div id="playnowdiv" style={{
                    paddingLeft: 100,
                }}>
                    <img src={discode__} />
                </div>
            </div>
        </div>
    )
}