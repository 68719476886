import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Sub_map_thumb, Sub_overwiew_page_002} from "../../const/ConstImages";
import "./GuideMid.css";

export const GuideMid = () => {
    return(
        <Container fluid>
            <Row>
                <Col style={{
                    background: `url(${Sub_overwiew_page_002}) no-repeat center center`,
                    height: 1012,
                }}>
                    <Row className="justify-content-center">
                        <Col md={6} className="guide2">
                            <div className="guide2_sub_title">
                                Explore the wide world and create your own story.
                            </div>
                            <div className="guide2_title">STORY OF AIKA</div>
                            <div className="chapters">
                                <Row className="justify-content-center">
                                    <Col md={3}>
                                        <div className="chapter">1st Chapter</div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="chapter">2dn Chapter</div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="chapter">3rd Chapter</div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="book_title">A Vast Open World</div>
                            <div className="book_content">
                                Ranger is a ranged class that uses the longbow and dagger as her main
                                weapons. She has skills that makes advangage of her dealskdjfakl. Ranger is
                                a ranged class that uses the longbow and dagger as her main weapons. She has
                                skills that makes advangage of her dealskdjfakl. Ranger is a ranged class
                                that uses the longbow and dagger as her main weapons. She has skills that
                                makes advangage of her dealskdjfakl
                            </div>
                            <div style={{
                                textAlign: "center",
                                background: `url(${Sub_map_thumb}) no-repeat center center`,
                                width: "100%",
                                height: 183
                            }}>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}