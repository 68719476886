import React from "react";
import "./LoginBody.css";
import {sub_log_logo} from "../../const/ConstImages";

export const LoginBody = () => {
    return (
        <div className="sub_content">
            <div style={{ width: "100%", height: 40 }} />
            <div className="login_box">
                <div className="logo">
                    <img src={sub_log_logo} alt="sub_log-logo" />
                </div>
                <div className="input_container">
                    <input type="text" style={{ height: 60, width: "90%", fontSize: 18 }} />
                </div>
                <div className="input_container">
                    <input type="text" style={{ height: 60, width: "90%", fontSize: 18 }} />
                </div>
                <div style={{ width: "100%", height: 100, textAlign: "center" }}>
                    <input type="button" defaultValue="Log in" />
                </div>
                <div style={{ width: "100%", height: 200, textAlign: "center" }}>
                    Find Password&nbsp;&nbsp;|&nbsp;&nbsp;Sign up for Free
                </div>
            </div>
        </div>

    )
}