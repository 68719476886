import React from "react";

import "./GuideBottom.css";
import {Col, Container, Row} from "react-bootstrap";
import {
    c_tab_bg,
    c_tab_Cleric_off,
    c_tab_DualGunners_off,
    c_tab_Paladins_off, c_tab_princess_hold,
    c_tab_Rifleman_off,
    c_tab_Warlocks_off, c_tab_Warrior_off,
    c_Warrior,
    Sub_overwiew_page_001, temp_job_screenshot
} from "../../const/ConstImages";

export const GuideBottom = () => {
    return(
        <Container fluid>
            <Row className="guide3">
                <Col style={{
                    background: `url(${Sub_overwiew_page_001}) no-repeat center center`,
                    height: 915,
                }}>
                    <Row className="justify-content-center">
                        <Col md={5} style={{
                            background: `url(${c_Warrior}) no-repeat center center`,
                            height: 915,
                        }} className="d-sm-none d-md-block">
                        </Col>
                        <Col md={7}>
                            <Row>
                                <Col md={7} className="top_sub_title">
                                    Warrior is a skilled melee fighter that uses a sword and shield.
                                </Col>
                                <Col md={7} className="top_big_title">
                                    Characters of AIKA
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{
                                    paddingTop: 90,
                                }}>
                                    <Row>
                                        <Col md={1} className="t_btn"><img src={c_tab_Warrior_off} alt="c-tab-Warlocks-off" /></Col>
                                        <Col md={1} className="t_btn"><img src={c_tab_Paladins_off} alt="c-tab-Paladins-off" /></Col>
                                        <Col md={1} className="t_btn"><img src={c_tab_Rifleman_off} alt="c-tab-Rifleman-off" /></Col>
                                        <Col md={1} className="t_btn"><img src={c_tab_DualGunners_off} alt="c-tab-DualGunners-off" /></Col>
                                        <Col md={1} className="t_btn"><img src={c_tab_Warlocks_off} alt="c-tab-Warlocks-off" /></Col>
                                        <Col md={1} className="t_btn"><img src={c_tab_Cleric_off} alt="c-tab-Cleric-off" /></Col>
                                        <Col md={1} className="t_btn"><img src={c_tab_princess_hold} alt="c-tab-princess-off" /></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{
                                paddingTop: 30,
                            }}>
                                <Col md={6}>
                                    <Row>
                                        <Col md={12} className="job">Warrior</Col>
                                        <Col md={12} className="job_sub">Warrior is a skilled melee fighter that uses a sword and shield.</Col>
                                        <Col md={12} className="job_desc">Warrior is a skilled melghter that uses a sword and sghter that uses a sword and see fighter that uses a sword and shield.<br />
                                            Warrior is a skilled melee fighter that uses a sword and shield.</Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <img src={temp_job_screenshot} alt="temp_job_screenshot" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}