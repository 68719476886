import React from "react";
import {GuideTop} from "../components/GuideTop/GuideTop";
import {GuideMid} from "../components/GuideMid/GuideMid";
import {GuideBottom} from "../components/GuideBottom/GuideBottom";

export const GuideScreen = () => {
    return(
        <>
            <GuideTop />
            <GuideMid />
            <GuideBottom />
        </>
    )
}