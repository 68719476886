import React from "react";
import {Col, Row} from "react-bootstrap";
import {sub_list_img} from "../../const/ConstImages";
import "./NoticeItem.css";

export const NoticeItem = () => {
    return(
        <>
            <Row className="justify-content-center" style={{paddingTop: 30, backgroundColor: "white"}}>
                <Col md={2} className="left_item rounded"
                     style={{
                         background: `url(${sub_list_img}) top / cover no-repeat`,
                         height: 200,
                     }}
                >
                </Col>
                <Col md={6}>
                    <Row>
                        <Col className="list_title">Patch Notes - July 26, 2023</Col>
                    </Row>
                    <Row>
                        <Col className="list_summary">
                            Here are the latest update details for Black Desert Online on July 26, 2023 (UTC).<br />
                            Today's patch contains 52 updates and is approximately 1.2 GB in size.<br />
                        </Col>
                    </Row>
                </Col>
                <Col md={2} className="list_date">Jul 26, 2023(UTC)</Col>
            </Row>
            <div className="notice_under_line" style={{backgroundColor: "white"}} />
        </>
    )
}