import React from "react";
import {Sub_top_002} from "../../const/ConstImages";

export const LoginHeader = () => {
    return(
        <div className="sub_top_image" style={{
            background: `url(${Sub_top_002}) no-repeat center center`,
        }}>
            <div className="text_content">Login</div>
        </div>
    )
}