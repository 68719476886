import React from "react";
import "./CmmnFooter.css";
import Dropdown from "react-bootstrap/Dropdown";
import {temp_footer_logos} from "../../const/ConstImages";

export const CmmnFooter = () => {
    return(
        <div
            className="maindiv"
            style={{ width: "100%", height: "100%", background: "#0b0b0b" }}
        >
            <div className="text-center justify-content-center" id="footer-div">
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic"
                         style={{
                             width: 257,
                             textAlign: "center",
                             height: 52,
                             // paddingTop: 12,
                             // paddingLeft: 23,
                             background: "rgb(0,0,0)",
                             fontSize: 17,
                             color: "rgb(89,89,89)",
                             borderStyle: "solid",
                             borderColor: "rgba(171,171,171,0.1)",
                             borderRadius: 0,
                             fontFamily: 'HeirofLightRegular, sans-serif',
                         }}
                    >
                       English
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        style={{
                            background: "black",
                            paddingBottom: 0,
                            paddingTop: 0,
                            height: 40,
                            color: "rgb(89,89,89)",
                        }}
                    >
                        <Dropdown.Item href="#/action-1" className="drop_item_language">English</Dropdown.Item>
                        <Dropdown.Item href="#/action-2" className="drop_item_language">Turkish</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div
                className="text-center"
                style={{
                    paddingTop: 40,
                    marginTop: 0,
                    paddingBottom: 45,
                    borderTop: "1px solid rgb(18,18,18)"
                }}
            >
                <a id="footer-link" href="#">
                    PRIVACY POLICY
                </a>
                |
                <a id="footer-link" href="#">
                    EULA
                </a>
                |
                <a id="footer-link" href="#">
                    TERM OF SERVICE
                </a>
                |
                <a id="footer-link" href="#">
                    UGC
                </a>
                <p
                    style={{
                        marginBottom: 0,
                        marginTop: 23,
                        color: "rgb(48,48,48)",
                        paddingTop: 12
                    }}
                >
                    ⓒ 2023 CBM Interactive Inc. All Right Reserved <br />
                    <br />
                </p>
                <img src={temp_footer_logos} alt="footer_logo" />
            </div>
        </div>

    );
}