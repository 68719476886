import React from "react";
import {DownloadHeader} from "../components/DownloadHeader/DownloadHeader";
import {DownloadBody} from "../components/DownloadBody/DownloadBody";

export const DownloadScreen = () => {
    return(
        <>
            <DownloadHeader />
            <DownloadBody />
        </>
    );
}