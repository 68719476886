import React from "react";
import "./MainYoutubeContainer.css";
import {Col, Container, Row} from "react-bootstrap";
import {MainNoticeItem} from "../MainNoticeItem/MainNoticeItem";
import {MainYoutubeItem} from "../MainYoutubeItem/MainYoutubeItem";

export const MainYoutubeContainer = () => {
    return (
        <div style={{width: "100%", height: "100%", backgroundColor: "#E6E6E6", paddingTop: 40}}>
            <Container
                style={{
                    paddingTop: 40,
                }}
                fluid
            >
                <Row className="justify-content-center justify-content-between" >
                    <Col md={1}></Col>
                    <Col md={3} className="align-middle text-center">
                        <MainYoutubeItem />
                    </Col>
                    <Col md={3} className="align-middle text-center">
                        <MainYoutubeItem />
                    </Col>
                    <Col md={3} className="align-middle text-center">
                        <MainYoutubeItem />
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </div>
    );
}