import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {MainScreen} from "./screens/MainScreen";
import CmmnNavbar from "./components/CmmnNavbar/CmmnNavbar";
import {CmmnFooter} from "./components/CmmnFooter/CmmnFooter";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {NoticeListScreen} from "./screens/NoticeListScreen";
import {NoticeDetailScreen} from "./screens/NoticeDetailScreen";
import {GuideScreen} from "./screens/GuideScreen";
import {DownloadScreen} from "./screens/DownloadScreen";
import {BanScreen} from "./screens/BanScreen";
import {LoginScreen} from "./screens/LoginScreen";

function App() {
    let buildEnv = process.env.REACT_APP_ENV;
    if(buildEnv === undefined) {
        buildEnv = '[local]';
    } else if(buildEnv === 'qa') {
        buildEnv = '[stag]';
    } else if(buildEnv === 'live') {
        buildEnv = '[prod]';
    }

    console.log("env = ", buildEnv);
  return (
    <BrowserRouter>
        <div className="App">
            <CmmnNavbar />
            <Routes>
                <Route path="/" element={<MainScreen />} />
                <Route path="/notice" element={<NoticeListScreen />} />
                <Route path="/notice_detail" element={<NoticeDetailScreen />} />
                <Route path="/guide" element={<GuideScreen />} />
                <Route path="/download" element={<DownloadScreen />} />
                <Route path="/ban" element={<BanScreen />} />
                <Route path="/login" element={<LoginScreen />} />
            </Routes>
            <CmmnFooter />
        </div>
    </BrowserRouter>
  );
}

export default App;
