import React from "react";
import "./MainOverview.css";
import {overview} from "../../const/ConstImages";

export const MainOverview = () => {
    return(
        <>

            <div id="overview-section">
                <video className="video" width={560} height={315} autoPlay loop muted>
                    <source
                        src="https://cdn.discordapp.com/attachments/860323695708078114/937865470332264588/gameplay2.mp4"
                        type="video/mp4"
                    />
                </video>
                <div className="text-center maindiv">
                    <h1 id="heading-overview">The Game Feature of Aika</h1>
                    <a id="overview-button" href="#">
                        <img
                            src={overview}
                        />
                    </a>
                </div>
            </div>
        </>
    );
}