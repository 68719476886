import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Sub_overwiew_page_001, temp_guide1_video} from "../../const/ConstImages";
import "./GuideTop.css";

export const GuideTop = () => {
    return(
        <Container fluid>
            <Row>
                <Col style={{
                    background: `url(${Sub_overwiew_page_001}) no-repeat center center`,
                    height: 1054,
                }}>
                    <Row>
                        <Col md={6} className="guide1">
                            <div className="guide1_sub_title">Go Beyond Limits</div>
                            <div className="guide1_title">
                                The daughter of the goddess Sylvia, the guardian of Kamaslvia.
                            </div>
                            <div className="guide1_content">
                                Ranger is a ranged class that uses the longbow and dagger as her main
                                weapons. She has skills that makes advangage of her dealskdjfakl. Ranger is
                                a ranged class that uses the longbow and dagger as her main weapons. She has
                                skills that makes advangage of her dealskdjfakl. Ranger is a ranged class
                                that uses the longbow and dagger as her main weapons. She has skills that
                                makes advangage of her dealskdjfakl
                            </div>
                            <div className="guide1_video">
                                <img src={temp_guide1_video} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}