import React from "react";
import {MainTopBanner} from "../components/MainTopBanner/MainTopBanner";
import {MainMidContainer} from "../components/MainMidContainer/MainMidContainer";
import {MainNoticeContainer} from "../components/MainNoticeContainer/MainNoticeContainer";
import {MainYoutubeContainer} from "../components/MainYoutubeContainer/MainYoutubeContainer";
import {MainOverview} from "../components/MainOverview/MainOverview";
import {MainJoinCommunity} from "../components/MainJoinCommunity/MainJoinCommunity";

export const MainScreen = () => {
    return (
        <>
            <MainTopBanner />
            <MainMidContainer />
            <MainNoticeContainer />
            <MainYoutubeContainer />
            <MainOverview />
            <MainJoinCommunity />
        </>
    );
}