import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {sub_list_img, Sub_top_002} from "../../const/ConstImages";
import "./NoticeHeader.css";

export const NoticeHeader = () => {
    return(
        <Container fluid>
            <Row>
                <Col md={12} style={{
                    background: `url(${Sub_top_002}) top / cover no-repeat`,
                    height: 475,
                }}>
                    <div className="title">Announcement</div>
                </Col>
            </Row>

        </Container>
    )
}