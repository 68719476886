import React from "react";
import {NoticeHeader} from "../components/NoticeHeader/NoticeHeader";
import {NoticeDetail} from "../components/NoticeDetail/NoticeDetail";

export const NoticeDetailScreen = () => {
    return(
        <div style={{backgroundColor: "white"}}>
            <NoticeHeader />
            <NoticeDetail />
        </div>
    );
}