import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import {main_top_img} from "../../const/ConstImages";
import "./MainTopBanner.css";

export const MainTopBanner = () => {
    let buildEnv = process.env.RECT_APP_ENV;
    if(buildEnv === undefined) {
        buildEnv = '[local]';
    } else if(buildEnv === 'qa') {
        buildEnv = '[stag]';
    } else if(buildEnv === 'live') {
        buildEnv = '';
    }
    return (
        <>
            <Carousel className="top_carousel">
                <Carousel.Item>
                    <img src={main_top_img} alt="main_top_img" className="d-block w-100" />
                    <Carousel.Caption>
                        <p>Open Beta : October 11th, 2023</p>
                        <p>Join us today and prepare to Rumble! {buildEnv}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    );
}