import React from "react";
import "./CmmnPaging.css";
import {sub_list_search} from "../../const/ConstImages";

export const CmmnPaging = () => {
    return(
        <div style={{backgroundColor:"white"}}>
            <div className="notice_pg_navi">
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
            </div>
            <div className="notice_search_box">
                <div className="search_box">
                    <table>
                        <tr>
                            <td style={{
                                width: "30%"
                            }}>
                                <select name="" id="" style={{
                                        height: 40,
                                        maxWidth: 300,
                                        minWidth: 100,
                                        width: "100%",
                                    }}>
                                    <option value="">All</option>
                                    <option value="">Update</option>
                                </select>
                            </td>
                            <td style={{
                                width: "50%"
                            }}>
                                <input type="text" placeholder="Search" style={{
                                    height: 36,
                                    width: 300
                                }}
                                />
                            </td>
                            <td>
                                <img src={sub_list_search} alt="search-icon" style={{
                                    marginTop: 5,
                                }} />
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}